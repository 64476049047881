.setting-container {
    padding: 20px;
    background-color: #f4f5f7;
}

.setting-tabs {
    margin-top: 50px; /* Space below the NavBar */
    background: #fff;
    border-radius: 8px;
    padding: 20px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.3);
    min-height: calc(100vh - 130px)
}

.ant-empty {
    margin-top: 50px;
}

.time-selector {
    cursor: pointer;
    color: #1677FF;
    margin-left: 8px;
    border: 1px solid #d9d9d9;
    padding: 5px 10px;
    border-radius: 4px;
    transition: background-color 0.3s, color 0.3s; /* 添加过渡效果 */
}

.time-selector:hover {
    background-color: #f5f5f5; /* 鼠标悬停时背景色 */
    color: #0056b3; /* 鼠标悬停时字体颜色 */
}