/* 全局样式 */
body {
    margin: 0;
    font-family: "Arial", sans-serif;
    background-color: #f4f5f7;
    
}

/* 登录页面容器 */
.login-page {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
}

/* 登录表单容器 */
.login-container {
    background-color: #ffffff;
    padding: 2rem;
    border-radius: 10px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    width: 400px;
    text-align: center;
    backdrop-filter: blur(10px); /* 磨砂模糊效果 */
    -webkit-backdrop-filter: blur(10px); /* 兼容Safari */
}

@media (max-width: 430px) {
    .login-container {
        padding: 1rem;
        width: 80%; /* 减少宽度以适应小屏 */
    }
}

/* 表单样式 */
.login-form-wrapper {
    margin-top: 2rem;
}

.login-input {
    height: 45px;
    border-radius: 5px;
}

.login-options {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1rem;
}

/* 登录按钮 */
.login-button {
    width: 100%;
    height: 45px;
    border-radius: 10px;
    background-color: #e63946;
    border: none;
    font-size: 1rem;
    font-weight: bold;
    color: white;
    transition: all 0.3s;
}

/* 注册链接 */
.register-link {
    margin-top: 1rem;
}

.register-link a {
    color: #007bff;
    text-decoration: none;
    font-size: 0.9rem;
}

.register-link a:hover {
    text-decoration: underline;
}

/* 调整警告message的样式 */
.ant-form-item {
    margin-bottom: 1.5rem;
}

.ant-form-item-explain {
    text-align: left; /* 左对齐 */
    padding-left: 10px; /* 与输入框的 padding-left 对齐 */
    color: #e63946; /* 自定义错误信息颜色（红色） */
    font-size: 0.85rem; /* 调整字体大小 */
}

/* 调整logo图片样式 */
.logo-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.login-logo {
    width: 120px;
    height: auto;
}

/* 背景 */
.background {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: radial-gradient(circle, rgba(63, 94, 251, 1) 0%, rgba(252, 70, 107, 1) 100%);
    overflow: hidden;
    z-index: -1;
}

/* 磨砂玻璃蒙版 */
.glass-overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(255, 255, 255, 0.15); /* 半透明白色覆盖 */
    backdrop-filter: blur(15px); /* 背景模糊 */
    -webkit-backdrop-filter: blur(15px); /* 兼容 Safari */
    border: 1px solid rgba(255, 255, 255, 0.3); /* 边框效果 */
    z-index: 0; /* 放置在背景和内容之间 */
}

/* 注册按钮样式 */
.register-button {
    background-color: #07c044; /* 绿色背景 */
    border: none;
    color: white;
    border-radius: 5px;
}

.register-button:hover {
    background-color: #06a337; /* 悬停时的深绿色 */
}
