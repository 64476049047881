/* 卡片样式 */
.custom-card {
    background: #fff;
    padding: 16px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1); /* 增加阴影效果 */
    border-radius: 8px;
    margin-bottom: 20px;
    width: 388px; /* 固定宽度 */
}

/* 卡片头部 */
.card-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 12px;
}

.card-title {
    font-size: 24px; /* 增大字体 */
    color: #000; /* 黑色标题 */
}

/* 卡片主体 */
.card-body {
    font-size: 14px; /* 缩小字体 */
    color: #666; /* 灰色字体 */
    margin-bottom: 16px;
}

/* 分割条 */
.divider {
    height: 1px;
    background-color: #f0f0f0;
    margin: 10px 0;
}

/* 卡片操作按钮 */
.card-actions {
    display: flex;
    align-items: center;
    gap: 10px;
}

/* 状态指示器 */
.status-indicator {
    width: 10px;
    height: 10px;
    border-radius: 50%;
}

/* 服务器 */
.server-name .ant-btn {
    margin-left: 8px; /* 保持间距 */
    font-size: 12px; /* 增大字体 */
    padding: 4px 8px; /* 减小内边距，让文字占更多空间 */
    height: auto; /* 自动调整高度以适应文字 */
    line-height: 1.2; /* 调整文字行高 */
}

.status-indicator.green {
    background-color: green;
}

.status-indicator.orange {
    background-color: orange;
}

.status-indicator.red {
    background-color: red;
}

/* Ant Design Dropdown 样式调整 */
.ant-dropdown-menu-item {
    font-size: 14px;
}

.ant-dropdown-menu-item:hover {
    background-color: #f0f0f0;
}
