.home-content {
    margin-top: 50px; /* 增加内容与导航栏之间的距离 */
    padding: 20px;
    display: flex;
    flex-wrap: wrap; /* 允许子项换行 */
    justify-content: center; /* 水平居中排列卡片 */
}

.no-accounts {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh; /* 全屏高度，确保居中 */
}

@media (min-width: 768px) {
    .home-content {
        justify-content: flex-start; /* 宽屏时卡片从左向右排列 */
        margin-top: 60px; /* 增加内容与导航栏之间的距离 */
        margin-left: 40px;
        gap: 20px;
    }
}