.help-container {
    margin-top: 60px; /* 增加内容与导航栏之间的距离 */
    padding: 20px;
    background-color: #f4f5f7; /* 页面背景色 */
    min-height: calc(100vh - 100px);
}

.help-header {
    display: flex;
    align-items: center;
    gap: 10px;
    margin-bottom: 20px;
    justify-content: center; /* 标题居中 */
}

.help-title {
    margin: 0;
    font-size: 24px; /* 更大的字体 */
    font-weight: bold;
    color: #333;
}

.help-collapse {
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

.help-rules {
    padding-left: 20px; /* 列表的内边距 */
    margin: 10px 0;
    line-height: 1.6;
    color: #555;
}

.help-rules li {
    margin-bottom: 8px;
}

/* Carousel 走马灯图片 */
.help-carousel {
    margin-top: 15px;
    margin-bottom: 20px;
    border-radius: 8px;
    overflow: hidden;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    text-align: center; /* 确保内容水平居中 */
}

.carousel-item {
    display: flex;
    align-items: center;
    justify-content: center; /* 横向居中 */
    padding: 10px;
}

.carousel-image {
    border-radius: 8px;
    max-width: 80%; /* 限制图片最大宽度 */
    object-fit: cover; /* 保持图片内容适配容器 */
    margin: 0 auto; /* 图片水平居中 */
}

/* 修改 Carousel 左右箭头颜色为黑色 */
.ant-carousel .slick-prev,
.ant-carousel .slick-next {
    color: black !important; /* 设置箭头颜色为黑色 */
    font-size: 20px; /* 调整箭头大小 */
    z-index: 2; /* 确保箭头层级正常 */
}

.ant-carousel .slick-prev:hover,
.ant-carousel .slick-next:hover {
    color: #000; /* 悬停时颜色保持黑色 */
}
