/* Empty state styles */
.log-empty {
    padding: 64px 0;
    text-align: center;
}

/* Content section styles */
.log-content {
    flex: 1;
    overflow: hidden;
}

/* Card styles for logs */
.log-card {
    height: 100vh;
    overflow-y: auto;
    background-color: #293545; /* Dark background */
    color: white;
    padding: 8px;
}

/* Log entry styles */
.log-entry {
    white-space: pre-wrap;
    font-size: 14px; /* 调整字体大小 */
    line-height: 1.5;
    margin-bottom: 8px; /* 减少日志条目的间距 */
}

/* Float button styles */
.log-float-button {
    --initial-position-bottom: 36px;
    --initial-position-right: 36px;
    z-index: 1000;
}
