.navbar {
    position: fixed; /* 吸附置顶 */
    top: 0;
    left: 0;
    right: 0;
    height: 50px;
    background: #fff;
    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.1);
    display: flex;
    align-items: center;
    padding: 0 20px;
    z-index: 1000; /* 确保优先显示 */
    justify-content: flex-end; /* 默认右对齐 */
}

.navbar.with-back-button {
    justify-content: space-between; /* 有返回按钮时两端对齐 */
}

/* 返回按钮样式 */
.back-button {
    display: flex;
    align-items: center;
    margin-right: auto;
}

.quota-display {
    display: flex;
    align-items: center;
    font-size: 18px;
    font-weight: bold;
    margin-right: 10px; /* 配额与右侧头像保持间距 */
}

.quota-display .quota-text {
    margin-left: 5px; /* 图标与数字间的间距 */
    color: gold; /* 配额数字颜色 */
}

/* 头像样式 */
.avatar-container {
    display: flex;
    align-items: center;
    cursor: pointer;
}

.user-avatar {
    width: 40px;
    height: 40px;
    border-radius: 50%; /* 圆形头像 */
    border: 2px solid #eee;
    transition: all 0.3s;
}

.user-avatar:hover {
    border-color: #1890ff; /* 悬停时边框高亮 */
}
