.shuttle-container {
    display: flex;
    justify-content: space-between;
    padding: 0px;
    gap: 10px;
}

.shuttle-column {
    flex: 1;
    padding: 10px;
    border: 1px solid #ddd; /* 边框颜色稍浅 */
    border-radius: 5px;
    background-color: #fff; /* 白色背景 */
    min-height: 600px; /* 固定高度 */
    max-height: 600px;
    overflow-y: auto; /* 支持滚动 */
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.05); /* 更轻的阴影效果 */
    padding-right: 20px;
}

.shuttle-item {
    padding: 8px 12px 8px 12px; /* 上下左右留白，确保视觉一致 */
    margin: 5px 10px 5px 0; /* 调整右侧间距 */
    cursor: grab;
    display: flex;
    justify-content: flex-start; /* 确保内容左对齐 */
    align-items: center;
    font-size: 12px;
    color: #333;

    background-color: #fff; /* 白色背景 */
    border: none; /* 边框颜色稍浅 */
    border-radius: 4px;
    transition: background-color 0.2s ease, transform 0.2s ease;
}

.shuttle-item:hover {
    background-color: #e6f7ff; /* 鼠标悬停时的背景色 */
    transform: translateY(-2px); /* 悬停时提升效果 */
}

.drag-preview {
    position: absolute;
    pointer-events: none;
    z-index: 9999;
    transform: translate(-50%, -50%);
    padding: 8px 12px;
    background-color: white;
    border-radius: 4px;
    border: 1px solid #ddd; /* 与普通项保持一致 */
    display: flex;
    justify-content: center;
    align-items: center;
}

.shuttle-image {
    max-width: 50px; /* 调整图片大小 */
    max-height: 50px;
    border-radius: 4px; /* 图片略微圆角 */
}

.image-badge-container {
    position: relative;
    display: inline-block;
}

.image {
    display: block;
    width: 100px;
    height: auto;
}

.badge {
    position: absolute;
    bottom: 5px; /* 从底部开始 5px */
    right: 5px; /* 从右侧开始 5px */
    background-color: rgba(0, 0, 0, 0.7); /* 使用黑色半透明背景 */
    color: white;
    font-size: 12px;
    padding: 2px 6px;
    border-radius: 8px;
    font-weight: bold;
    z-index: 1;
    opacity: 0.8; /* 设置整体透明度 */
}

.column-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 8px;
}

.column-header h3 {
    margin: 0;
    font-size: 16px;
    font-weight: bold;
    color: #333; /* 标题颜色 */
}

.column-header button {
    font-size: 14px;
    padding: 2px 8px;
    cursor: pointer;
    background: #fff; /* 白色背景 */
    border: 1px solid #ddd; /* 边框颜色稍浅 */
    border-radius: 4px;
    color: #333; /* 按钮文字颜色 */
    transition: background-color 0.2s ease;
}

.column-header button:hover {
    background-color: #f5f5f5; /* 浅灰色悬停背景 */
}

.collapse-title {
    font-size: 14px; /* 调整折叠面板标题字体大小 */
    font-weight: normal; /* 设置为普通字体权重 */
    color: #666; /* 使用更浅的灰色 */
}

.collapse-panel {
    border: none; /* 移除默认边框 */
}

.collapse-panel-content {
    padding: 0px; /* 添加内部边距 */
}